window.hasFormChanged = function(id) {
  let section = document.getElementById(id+'_section')
  let formElement = section.querySelector('form.section-form')
  if(formElement === null) return false
  let formEntries = new FormData(formElement).entries()
  let currentFormValue = JSON.stringify(Object.fromEntries(formEntries))
  let initialFormValue = formElement.dataset.initialFormValue
  return initialFormValue && (currentFormValue !== initialFormValue)
}

window.hasOpenSubForm = function(id) {
  let section = document.getElementById(id+'_section')
  let hasOpenForm = section.querySelectorAll('form:not(.section-form)').length > 0
  return hasOpenForm
}

window.saveAndContinue = function(from, to) {
  let section = document.getElementById(from+'_section')
  let saveButton = section.getElementsByClassName('save-and-continue')[0]
  section.querySelector("input[name='next_tab']").value = to
  saveButton.click()
}

window.discardChanges = function(id) {
  let section = document.getElementById(id+'_section')
  let sectionForm = section.querySelector('form.section-form')
  if (sectionForm) {
    sectionForm.reset()
  }
  let openForms = section.querySelectorAll('form:not(.section-form)')
  if (openForms.length > 0) {
    openForms[0].getElementsByClassName('cancel-button')[0].click()
  }
}